import React, { useState, useEffect } from 'react'
import _, { forEach } from 'lodash'
import GraphCardMilkByDay from '../AnalyticsMilkByDay/GrpahCardMilkByDay'
import Grid from '@material-ui/core/Grid'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { makeStyles } from '@material-ui/core/'
import es from 'date-fns/locale/es'
import { format, startOfDay, endOfDay } from 'date-fns'
import {
  inventario_ganadero,
  desempeno_prod,
  eventos_sanitarios,
  manejo_pastoreo,
} from './examples/dataColumns'
import FilterMilkByDay from 'components/AnalyticsMilkByDay/FilterMilkbyDay'
import { cattleSummaryMonthlyByPdIds } from '../../utils/api/models/MilkReports'
import { constant } from 'lodash/fp'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '30px',
  },
  indicadoresRoot: {
    width: '100%',
  },
  heading: {
    //marginLeft: '25px',
    fontWeight: 'bold',
    fontSize: '2rem',
    lineHeight: 1.334,
    letterSpacing: '0em',
  },
}))

function filterByKey(key, id, data) {
  const sortedData = data.sort((a, b) => new Date(a.date) - new Date(b.date))
  //
  const nivoData = { id: id, data: [] }
  sortedData.forEach((row, index) => {
    nivoData.data.push({
      x: row.date.slice(0, 23),
      y: key(row),
    })
  })
  //console.log(nivoData)
  //console.log('nivo ', nivoData)
  return nivoData
}

function filterByKeyBoxPlot(key, id, data) {
  const sortedData = data.sort((a, b) => new Date(a.date) - new Date(b.date))
  //console.log(sortedData)
  const nivoData = []
  sortedData.forEach((row, index) => {
    nivoData.push({
      group: format(new Date(row.date), 'yyyy'),
      subgroup: id,
      value: key(row),
    })
  })
  //console.log("boxplot: ",nivoData)
  return nivoData
}

export default function AnalyticsMilkByMonth() {
  const [fetchData, setFetchData] = useState({})
  const [intervalDate, setIntervalDate] = useState({
    from: new Date(),
    to: new Date(),
  })
  const classes = useStyles()
  const locale = es

  async function getData(date_from, date_to, productive_units_ids) {
    let format_from_date = new Date(date_from.year, date_from.month - 1, 1)
    let format_to_date = new Date(
      new Date(date_to.year, date_to.month - 1 + 1, 1) - 1,
    )

    setIntervalDate({ from: format_from_date, to: format_to_date })

    const data = await cattleSummaryMonthlyByPdIds(
      startOfDay(format_from_date),
      endOfDay(format_to_date),
      productive_units_ids,
    )

    let storage = {}
    data.forEach((row) => {

      let pd = row['productive_unit_id']

      if (storage[pd]) {
        storage[pd]['data'].push(row)
      } else {
        storage[pd] = {data: [row], name: `${row['productive_unit_name']} - ${row['farm_name']}`}
      }
      
    })

    setFetchData(storage)
  }

  return (
    <main>
      <ExpansionPanel defaultExpanded>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant='h1' className={classes.heading}>
            Filtros
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <FilterMilkByDay type_date='months' getData={getData} />
        </ExpansionPanelDetails>
      </ExpansionPanel>
      {Object.keys(fetchData).length !== 0 && (
        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant='h1' className={classes.heading}>
              Inventario ganadero
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div className={classes.indicadoresRoot}>
              {inventario_ganadero.map((indicador, i) => {
                return (
                  <Indicadores
                    intervalDate={intervalDate}
                    dataColumn={indicador.data_column_fn}
                    data={fetchData}
                    name={indicador.name}
                    units={indicador.units}
                  />
                )
              })}
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      )}
      {Object.keys(fetchData).length !== 0 && (
        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant='h1' className={classes.heading}>
              Manejo pastoreo
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div className={classes.indicadoresRoot}>
              {manejo_pastoreo.map((indicador, i) => {
                return (
                  <Indicadores
                    intervalDate={intervalDate}
                    dataColumn={indicador.data_column_fn}
                    data={fetchData}
                    name={indicador.name}
                    units={indicador.units}
                  />
                )
              })}
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      )}
      {Object.keys(fetchData).length !== 0 && (
        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant='h1' className={classes.heading}>
              Desempeño productivo
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div className={classes.indicadoresRoot}>
              {desempeno_prod.map((indicador, i) => {
                return (
                  <Indicadores
                    intervalDate={intervalDate}
                    dataColumn={indicador.data_column_fn}
                    data={fetchData}
                    name={indicador.name}
                    units={indicador.units}
                  />
                )
              })}
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      )}
      {Object.keys(fetchData).length !== 0 && (
        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant='h1' className={classes.heading}>
              Eventos sanitarios
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div className={classes.indicadoresRoot}>
              {eventos_sanitarios.map((indicador, i) => {
                return (
                  <Indicadores
                    intervalDate={intervalDate}
                    dataColumn={indicador.data_column_fn}
                    data={fetchData}
                    name={indicador.name}
                    units={indicador.units}
                  />
                )
              })}
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      )}
    </main>
  )
}

function Indicadores({
  dataColumn,
  name,
  units,
  data = {},
  intervalDate = { intervalDate },
}) {
  const classes = useStyles()

  let curve = []
  Object.entries(data).forEach(([key, value]) => {
    curve.push(filterByKey(dataColumn, value.name, value.data))
  })

  console.log('indicador_data ', curve)

  /*const curve = [
    filterByKey(dataColumn, "unidad 2", json2),
    filterByKey(dataColumn, "unidad 4", json4),
    filterByKey(dataColumn, "unidad 6", json6),
    filterByKey(dataColumn, "unidad 10", json10),
  ]*/

  let box_plot = []
  Object.entries(data).forEach(([key, value]) => {
    box_plot.push(filterByKeyBoxPlot(dataColumn, value.name, value.data))
  })
  box_plot = _.flatten(box_plot)
  const box_plot_sorted_array = _.sortBy(box_plot, 'group')

  /*const box_plot = _.flatten([
    filterByKeyBoxPlot(dataColumn, "unidad 2", json2),
    filterByKeyBoxPlot(dataColumn, "unidad 4", json4),
    filterByKeyBoxPlot(dataColumn, "unidad 6", json6),
    filterByKeyBoxPlot(dataColumn, "unidad 10", json10),
  ])
  const box_plot_sorted_array = _.sortBy(box_plot, 'group');*/

  return (
    <ExpansionPanel>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant='h3'>{name}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <GraphCardMilkByDay
              intervalDate={intervalDate}
              data={curve}
              data_box={box_plot_sorted_array}
              title={`${name}`}
              month={true}
              units={units}
              data_type={['curve', 'boxplot', 'table']}
            />
          </Grid>
        </Grid>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}
