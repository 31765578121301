import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import GraphCardMilkByDay from '../AnalyticsMilkByDay/GrpahCardMilkByDay'
import Grid from '@material-ui/core/Grid'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { makeStyles } from '@material-ui/core/'
import es from 'date-fns/locale/es'
import { format, startOfDay, endOfDay } from 'date-fns'
import FilterMilkByDay from 'components/AnalyticsMilkByDay/FilterMilkbyDay'
import {
  problemas_podales,
  problemas_podales_porcentaje,
  problemas_podales_total_porcentaje,
  partos,
  partos_porcentaje,
  sanidad_mamaria,
  sanidad_mamaria_porcentaje,
  problemas_digestivos,
  problemas_digestivos_porcentaje,
  problemas_metabolicos,
  problemas_metabolicos_porcentaje,
  problemas_reproductivos,
  problemas_reproductivos_porcentaje,
  masa,
  vientres,
  total,
  eliminacion,
  produccion_dia,
  produccion_dia_porcentaje,
  tc,
  cosecha,
  rotacion,
  superficie,
  avance,
  carga_animal,
  cosecha_vaca,
  cosecha_rx,
  prod_por_vaca,
  del,
  cc,
  peso_vivo,
  Proteina_grasa,
  Proteina_grasa_g,
  solidos_ha_dia,
  solidos_ha_mes,
  Urea,
  rcs,
  ufc,
  lv,
} from './examples/dataColumns'
import { cattleSummaryMonthlyByPdIds } from '../../utils/api/models/MilkReports'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '30px',
  },
  indicadoresRoot: {
    width: '100%',
  },
  heading: {
    //marginLeft: '25px',
    fontWeight: 'bold',
    fontSize: '2rem',
    lineHeight: 1.334,
    letterSpacing: '0em',
  },
}))

function filterByKey(key, id, data) {
  const sortedData = data['cattle_summary'].sort(
    (a, b) => new Date(a.date) - new Date(b.date),
  )
  //console.log(sortedData)
  const nivoData = { id: id, data: [] }
  sortedData.forEach((row, index) => {
    nivoData.data.push({
      x: row.date,
      y: key(row),
    })
  })
  console.log(nivoData)
  return nivoData
}

function filterCompoundKeys(indexes, data) {
  //  const sortedData = data['cattle_summary'].sort(
  //    (a, b) => new Date(a.date) - new Date(b.date),
  //  )
  //console.log(sortedData)
  const nivoData = []
  //console.log(data.data)
  indexes?.forEach((indicator, index) => {
    const payload = {
      name: indicator.name,
    }
    data.data?.forEach((datos) => {
      payload[`${datos['productive_unit_name']}-${datos['farm_name']}`] =
        indicator.data_column_fn(datos)
    })
    nivoData.push(payload)
  })
  //console.log(nivoData)
  return nivoData
}

function tableData(indexes, data) {
  const table_data = []

  data.data?.forEach((datos) => {
    const payload = {
      name: datos['productive_unit_id'],
    }

    indexes?.forEach((indicator, index) => {
      payload[indicator.name] = indicator.data_column_fn(datos)
    })

    table_data.push(payload)
  })

  //console.log("table: ",table_data)
  return table_data
}

const desempeno_productivo = [
  {
    label: 'Producción por vaca (l/v/d)',
    data: [
      {
        data: prod_por_vaca,
        units: 'int',
      },
    ],
  },
  {
    label: 'Días en leche',
    data: [
      {
        data: del,
        units: 'int',
      },
    ],
  },
  {
    label: 'Condición corporal (1 a 5)',
    data: [
      {
        data: cc,
        units: 'int',
      },
    ],
  },
  {
    label: 'Peso vivo (KgPV)',
    data: [
      {
        data: peso_vivo,
        units: 'int',
      },
    ],
  },
  {
    label: 'Proteína y grasa láctea (%)',
    data: [
      {
        data: Proteina_grasa,
        units: 'int',
      },
    ],
  },
  {
    label: 'Proteína y grasa láctea (g/v/d)',
    data: [
      {
        data: Proteina_grasa_g,
        units: 'int',
      },
    ],
  },
  {
    label: 'Sólidos día (kg/ha/dia)',
    data: [
      {
        data: solidos_ha_dia,
        units: 'int',
      },
    ],
  },
  {
    label: 'Sólidos mes (kg/ha/mes)',
    data: [
      {
        data: solidos_ha_mes,
        units: 'int',
      },
    ],
  },
  {
    label: 'Urea en leche (mg/ml)',
    data: [
      {
        data: Urea,
        units: 'int',
      },
    ],
  },
  {
    label: 'RCS (Mcel/ml)',
    data: [
      {
        data: rcs,
        units: 'int',
      },
    ],
  },
  {
    label: 'UFC (Mcufc/ml)',
    data: [
      {
        data: ufc,
        units: 'int',
      },
    ],
  },
]

const manejo_pasterio = [
  {
    label: 'Tasa de crecimiento actual',
    data: [
      {
        data: tc,
        units: 'int',
      },
    ],
  },
  {
    label: 'Cosecha ingreso pastoreo',
    data: [
      {
        data: cosecha,
        units: 'int',
      },
    ],
  },
  {
    label: 'Rotación (días)',
    data: [
      {
        data: rotacion,
        units: 'int',
      },
    ],
  },
  {
    label: 'Superficie de rotación (ha)',
    data: [
      {
        data: superficie,
        units: 'int',
      },
    ],
  },
  {
    label: 'Superficie pastoreo día (ha)',
    data: [
      {
        data: avance,
        units: 'int',
      },
    ],
  },
  {
    label: 'Carga animal (UA/ha)',
    data: [
      {
        data: carga_animal,
        units: 'int',
      },
    ],
  },
  {
    label: 'Cosecha/vaca | (MgMs/v/d)',
    data: [
      {
        data: cosecha_vaca,
        units: 'int',
      },
    ],
  },
  {
    label: 'Cosecha RX',
    data: [
      {
        data: cosecha_rx,
        units: 'int',
      },
    ],
  },
  {
    label: 'Producción día (l)',
    data: [
      {
        data: produccion_dia,
        units: 'int',
      },
      {
        data: produccion_dia_porcentaje,
        units: 'porcentaje',
      },
    ],
  },
  {
    label: 'Litros por vaca',
    data: [
      {
        data: lv,
        units: 'int',
      },
    ],
  },
]

const inventario_ganadero = [
  {
    label: 'Vaca masa',
    data: [
      {
        data: masa,
        units: 'int',
      },
    ],
  },
  {
    label: 'Vientres',
    data: [
      {
        data: vientres,
        units: 'int',
      },
    ],
  },
  {
    label: 'Total',
    data: [
      {
        data: total,
        units: 'int',
      },
    ],
  },
  {
    label: 'Eliminación',
    data: [
      {
        data: eliminacion,
        units: 'int',
      },
    ],
  },
]
const eventos_sanitarios = [
  {
    label: 'Problemas digestivos',
    data: [
      {
        data: problemas_digestivos,
        units: 'int',
      },
      {
        data: problemas_digestivos_porcentaje,
        units: 'porcentaje',
      },
    ],
  },
  {
    label: 'Sanidad mamaria',
    data: [
      {
        data: sanidad_mamaria,
        units: 'int',
        explicacion: '',
      },
      {
        data: sanidad_mamaria_porcentaje,
        units: 'porcentaje',
      },
    ],
  },
  {
    label: 'Problemas podales',
    data: [
      {
        data: problemas_podales,
        units: 'int',
        explicacion: '',
      },
      {
        data: problemas_podales_porcentaje,
        units: 'porcentaje',
      },
      {
        data: problemas_podales_total_porcentaje,
        units: 'porcentaje',
      },
    ],
  },
  {
    label: 'Problemas metabólicos',
    data: [
      {
        data: problemas_metabolicos,
        units: 'int',
        explicacion: '',
      },
      {
        data: problemas_metabolicos_porcentaje,
        units: 'porcentaje',
      },
    ],
  },
  {
    label: 'Problemas reproductivos',
    data: [
      {
        data: problemas_reproductivos,
        units: 'int',
        explicacion: '',
      },
      {
        data: problemas_reproductivos_porcentaje,
        units: 'porcentaje',
      },
    ],
  },
  {
    label: 'Partos',
    data: [
      {
        data: partos,
        units: 'int',
        explicacion: '',
      },
      {
        data: partos_porcentaje,
        units: 'porcentaje',
      },
    ],
  },
]

export default function AnalyticsMilkFixedMonth() {
  const [fetchData, setFetchData] = useState({ data: [] })
  const [intervalDate, setIntervalDate] = useState({
    from: new Date(),
    to: new Date(),
  })
  const classes = useStyles()
  const locale = es

  async function getData(date, productive_units_ids) {
    let format_from_date = new Date(date.year, date.month - 1, 1)
    let format_to_date = new Date(
      new Date(date.year, date.month + 1 - 1, 1) - 1,
    )

    //console.log('formats: ', format_from_date, format_to_date)
    setIntervalDate({ from: format_from_date, to: format_to_date })

    const data = await cattleSummaryMonthlyByPdIds(
      startOfDay(format_from_date),
      endOfDay(format_to_date),
      productive_units_ids,
    )

    setFetchData({ data: data })
  }

  return (
    <main>
      <ExpansionPanel defaultExpanded>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant='h1' className={classes.heading}>
            Filtros
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <FilterMilkByDay type_date='fixed_months' getData={getData} />
        </ExpansionPanelDetails>
      </ExpansionPanel>
      {Object.keys(fetchData.data).length !== 0 && (
        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant='h1' className={classes.heading}>
              Inventario ganadero
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div className={classes.indicadoresRoot}>
              {inventario_ganadero.map((grupo_indicadores, i) => {
                return (
                  <IndicadoresFixed
                    intervalDate={intervalDate}
                    fetchData={fetchData}
                    indexes_list={grupo_indicadores.data}
                    name={grupo_indicadores.label}
                    units={'int'}
                  />
                )
              })}
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      )}
      {Object.keys(fetchData.data).length !== 0 && (
        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant='h1' className={classes.heading}>
              Manejo de pastoreo
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div className={classes.indicadoresRoot}>
              {manejo_pasterio.map((grupo_indicadores, i) => {
                return (
                  <IndicadoresFixed
                    intervalDate={intervalDate}
                    fetchData={fetchData}
                    indexes_list={grupo_indicadores.data}
                    name={grupo_indicadores.label}
                    units={'int'}
                  />
                )
              })}
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      )}
      {Object.keys(fetchData.data).length !== 0 && (
        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant='h1' className={classes.heading}>
              Desempeño productivo
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div className={classes.indicadoresRoot}>
              {desempeno_productivo.map((grupo_indicadores, i) => {
                return (
                  <IndicadoresFixed
                    intervalDate={intervalDate}
                    fetchData={fetchData}
                    indexes_list={grupo_indicadores.data}
                    name={grupo_indicadores.label}
                    units={'int'}
                  />
                )
              })}
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      )}
      {Object.keys(fetchData.data).length !== 0 && (
        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant='h1' className={classes.heading}>
              Eventos sanitarios
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div className={classes.indicadoresRoot}>
              {eventos_sanitarios.map((grupo_indicadores, i) => {
                return (
                  <IndicadoresFixed
                    intervalDate={intervalDate}
                    fetchData={fetchData}
                    indexes_list={grupo_indicadores.data}
                    name={grupo_indicadores.label}
                    units={'int'}
                  />
                )
              })}
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      )}
    </main>
  )
}

function IndicadoresFixed({
  indexes_list,
  name,
  units,
  fetchData,
  intervalDate,
}) {
  const classes = useStyles()
  const keys = []

  fetchData.data?.forEach((datos) => {
    keys.push(`${datos['productive_unit_name']}-${datos['farm_name']}`)
  })

  //console.log("fechData: ", keys, fetchData)
  return (
    <ExpansionPanel>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant='h3'>{name}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Grid container spacing={3}>
          {indexes_list?.map((indexes, i) => {
            const d = filterCompoundKeys(indexes.data, fetchData)

            const table_keys = []

            indexes.data?.forEach((datos) => {
              table_keys.push(`${datos.name}`)
            })

            const t = {
              data: tableData(indexes.data, fetchData),
              keys: table_keys,
            }

            //console.log("indexes :",indexes)
            return (
              <Grid item xs={12}>
                <GraphCardMilkByDay
                  intervalDate={intervalDate}
                  data={d}
                  title={name}
                  table_data={t}
                  month={false}
                  units={indexes.units}
                  graph='bar'
                  keys={keys}
                  data_type={['bar', 'table']}
                  fixed={true}
                />
              </Grid>
            )
          })}
        </Grid>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}
