//GOBAL FUNCTIONS
const problemas_podales_totales_fn = (x) => {
  return (
    x['sanitary_event_cobblestones'] +
    x['sanitary_event_dermatitis'] +
    x['sanitary_event_doble_sole'] +
    x['sanitary_event_others_legs_problems']
  )
}

const total_cows_fn = (x) => {
  return x['dairy_cows'] + x['dry_cows'] + x['cull_cows'] + x['pre_partum_cows']
}

const total_partum_fn = (x) => {
  return x['sanitary_event_cows_births'] + x['sanitary_event_heifers_births']
}

const total_prod_milk_fn = (x) => {
  return (
    x['production_milk_pond'] +
    x['production_milk_calves'] +
    x['production_milk_staff'] +
    x['production_milk_discard']
  )
}

const total_vientres_fn = (x) => {
  return (
    total_cows_fn(x) +
    x['pre_partum_heifers'] +
    x['pregnant_heifers'] +
    x['breading_heifers'] +
    x['pre_breading_heifers'] +
    x['rearing'] +
    x['calves']
  )
}

const tasa_crecimiento_fn = (x) => {
  return x['grazing_harvest'] !== 0
    ? x['grazing_harvest'] / x['grazing_rotation']
    : null
}

const carga_animal_fn = (x) => {
  return total_cows_fn(x) !== 0
    ? (total_cows_fn(x) / x['surface_available']).toFixed(2)
    : null
}

const cosecha_vaca_fn = (x) => {
  return (
    total_cows_fn(x) /
    (
      (total_cows_fn(x) !== 0
        ? total_cows_fn(x) / x['surface_available']
        : null) * x['grazing_harvest']
    ).toFixed(2)
  )
}

const lv_real_fn = (x) => {
  return total_cows_fn(x) !== 0
    ? total_cows_fn(x) / total_prod_milk_fn(x)
    : null
}

const solidos_totales_fn = (x) => {
  let resutl1 = x['performance_milk_fat']
  let result2 = x['performance_milk_protein']
  return (resutl1 + result2).toFixed(2)
}
//DATA COLUMNS

//DESEMPEÑO PRODUCTIVO

export const prod_por_vaca = [
  {
    name: 'Producción por vaca (l/v/d)',
    data_column_fn: (x) => {
      return (lv_real_fn(x) * 1.03).toFixed(2)
    },
  },
]

export const del = [
  {
    name: 'Días en leche',
    data_column_fn: (x) => {
      return x['performance_days_on_milk']
    },
  },
]

export const cc = [
  {
    name: 'Condición corporal (1 a 5)',
    data_column_fn: (x) => {
      return x['performance_CC']
    },
  },
]

export const peso_vivo = [
  {
    name: 'Peso vivo (KgPV)',
    data_column_fn: (x) => {
      return x['performance_living_weight']
    },
  },
]

export const Proteina_grasa = [
  {
    name: 'Proteína (%)',
    data_column_fn: (x) => {
      return x['performance_milk_protein']
    },
  },
  {
    name: 'Grasa (%)',
    data_column_fn: (x) => {
      return x['performance_milk_fat']
    },
  },
]

export const Proteina_grasa_g = [
  {
    name: 'Proteína (g/v/d)',
    data_column_fn: (x) => {
      return (x['performance_milk_protein'] * lv_real_fn(x) * 10).toFixed(2)
    },
  },
  {
    name: 'Grasa (g/v/d)',
    data_column_fn: (x) => {
      return (x['performance_milk_fat'] * lv_real_fn(x) * 10).toFixed(2)
    },
  },
  {
    name: 'Total (g/v/d)',
    data_column_fn: (x) => {
      return solidos_totales_fn(x)
    },
  },
]

export const solidos_ha_dia = [
  {
    name: 'Sólidos día (kg/ha/dia)',
    data_column_fn: (x) => {
      return ((solidos_totales_fn(x) * carga_animal_fn(x)) / 1000).toFixed(2)
    },
  },
]

export const solidos_ha_mes = [
  {
    name: 'Sólidos mes (kg/ha/mes)',
    data_column_fn: (x) => {
      return (
        ((solidos_totales_fn(x) * carga_animal_fn(x)) / 1000) *
        30
      ).toFixed(2)
    },
  },
]

export const Urea = [
  {
    name: 'Urea en leche (mg/ml)',
    data_column_fn: (x) => {
      return x['performance_urea']
    },
  },
]

export const rcs = [
  {
    name: 'RCS (Mcel/ml)',
    data_column_fn: (x) => {
      return x['performance_count_somatic_cells']
    },
  },
]

export const ufc = [
  {
    name: 'UFC (Mcufc/ml)',
    data_column_fn: (x) => {
      return x['performance_UFC']
    },
  },
]

//MANEJO PASTOREO

export const tc = [
  {
    name: 'Tasa de crecimiento actual',
    data_column_fn: (x) => {
      return tasa_crecimiento_fn(x)
    },
  },
]

export const cosecha = [
  {
    name: 'Cosecha ingreso pastoreo',
    data_column_fn: (x) => {
      return x['grazing_harvest']
    },
  },
]

export const rotacion = [
  {
    name: 'Rotación (días)',
    data_column_fn: (x) => {
      return x['grazing_rotation']
    },
  },
]

export const superficie = [
  {
    name: 'Superficie de rotación (ha)',
    data_column_fn: (x) => {
      return x['surface_available']
    },
  },
]

export const avance = [
  {
    name: 'Superficie pastoreo día (ha)',
    data_column_fn: (x) => {
      return x['surface_available'] !== 0
        ? (x['surface_available'] / x['grazing_rotation']).toFixed(2)
        : null
    },
  },
]

export const carga_animal = [
  {
    name: 'Carga animal (UA/ha)',
    data_column_fn: (x) => {
      return carga_animal_fn(x)
    },
  },
]

export const cosecha_vaca = [
  {
    name: 'Cosecha/vaca (MgMs/v/d)',
    data_column_fn: (x) => {
      return cosecha_vaca_fn(x)
    },
  },
]

export const cosecha_rx = [
  {
    name: 'Cosecha Rx (KgMs/ha)',
    data_column_fn: (x) => {
      return x['grazing_harvest'] * x['grazing_rotation']
    },
  },
]

export const lv = [
  {
    name: 'Litros por vaca total',
    data_column_fn: (x) => {
      return lv_real_fn(x)
    },
  },
]

export const produccion_dia = [
  {
    name: 'Litros estanque',
    data_column_fn: (x) => {
      return x['production_milk_pond']
    },
  },
  {
    name: 'Litros terneros',
    data_column_fn: (x) => {
      return x['production_milk_calves']
    },
  },
  {
    name: 'Litros personal',
    data_column_fn: (x) => {
      return x['production_milk_staff']
    },
  },
  {
    name: 'Litros descarte',
    data_column_fn: (x) => {
      return x['production_milk_discard']
    },
  },
  {
    name: 'Total litros',
    data_column_fn: (x) => {
      return total_prod_milk_fn(x)
    },
  },
]

export const produccion_dia_porcentaje = [
  {
    name: 'Litros estanque (%)',
    data_column_fn: (x) => {
      return total_partum_fn(x) === 0
        ? null
        : ((x['production_milk_pond'] / total_prod_milk_fn(x)) * 100).toFixed(2)
    },
  },
  {
    name: 'Litros terneros (%)',
    data_column_fn: (x) => {
      return total_partum_fn(x) === 0
        ? null
        : ((x['production_milk_calves'] / total_prod_milk_fn(x)) * 100).toFixed(
            2,
          )
    },
  },
  {
    name: 'Litros personal (%)',
    data_column_fn: (x) => {
      return total_partum_fn(x) === 0
        ? null
        : ((x['production_milk_staff'] / total_prod_milk_fn(x)) * 100).toFixed(
            2,
          )
    },
  },
  {
    name: 'Litros descarte (%)',
    data_column_fn: (x) => {
      return total_partum_fn(x) === 0
        ? null
        : (
            (x['production_milk_discard'] / total_prod_milk_fn(x)) *
            100
          ).toFixed(2)
    },
  },
]

//INVENTARIO GANADERO
export const masa = [
  {
    name: 'Total vacas ordeña',
    data_column_fn: (x) => {
      return x['dairy_cows']
    },
  },
  {
    name: 'Vacas secas preñadas',
    data_column_fn: (x) => {
      return x['pregnant_heifers']
    },
  },
  {
    name: 'Vacas desecho',
    data_column_fn: (x) => {
      return x['cull_cows']
    },
  },
  {
    name: 'Vacas preparto',
    data_column_fn: (x) => {
      return x['pre_partum_cows']
    },
  },
  {
    name: 'Vacas masa',
    data_column_fn: (x) => {
      return total_cows_fn(x)
    },
  },
]

export const vientres = [
  {
    name: 'Vaquillas preparto',
    data_column_fn: (x) => {
      return x['pre_partum_heifers']
    },
  },
  {
    name: 'Vaquillas preñadas',
    data_column_fn: (x) => {
      return x['pregnant_heifers']
    },
  },
  {
    name: 'Vaquillas encaste',
    data_column_fn: (x) => {
      return x['breading_heifers']
    },
  },
  {
    name: 'Vaquillas pre encaste',
    data_column_fn: (x) => {
      return x['pre_breading_heifers']
    },
  },
  {
    name: 'Recría',
    data_column_fn: (x) => {
      return x['rearing']
    },
  },
  {
    name: 'Ternera',
    data_column_fn: (x) => {
      return x['calves']
    },
  },
  {
    name: 'Total vientres',
    data_column_fn: (x) => {
      return total_vientres_fn(x)
    },
  },
]

export const total = [
  {
    name: 'Novillos y vaquillas engorda',
    data_column_fn: (x) => {
      return x['steers_heifers_fattening']
    },
  },
  {
    name: 'Total animales lechería',
    data_column_fn: (x) => {
      return x['steers_heifers_fattening'] + total_vientres_fn(x)
    },
  },
]

export const eliminacion = [
  {
    name: 'Ventas',
    data_column_fn: (x) => {
      return x['elimination_sells']
    },
  },
  {
    name: 'Muertes',
    data_column_fn: (x) => {
      return x['elimination_deaths']
    },
  },
  {
    name: 'Total animales campo',
    data_column_fn: (x) => {
      return (
        x['steers_heifers_fattening'] +
        total_vientres_fn(x) -
        (x['elimination_deaths'] + x['elimination_sells'])
      )
    },
  },
]

//EVENTOS SANITARIOS

export const problemas_reproductivos = [
  {
    name: 'Retención de placenta (nº)',
    data_column_fn: (x) => {
      return x['sanitary_event_retention_placenta']
    },
  },
  {
    name: 'Metritis (nº)',
    data_column_fn: (x) => {
      return x['sanitary_event_metritis']
    },
  },
  {
    name: 'Anestro > 90DEL',
    data_column_fn: (x) => {
      return x['sanitary_event_anestrus']
    },
  },
  {
    name: 'Quistes ováricos',
    data_column_fn: (x) => {
      return x['sanitary_event_ovarian_cyst']
    },
  },
]

export const problemas_reproductivos_porcentaje = [
  {
    name: 'Retención de placenta (%)',
    data_column_fn: (x) => {
      return total_partum_fn(x) === 0
        ? null
        : (
            (x['sanitary_event_retention_placenta'] / total_partum_fn(x)) *
            100
          ).toFixed(2)
    },
  },
  {
    name: 'Metritis (%)',
    data_column_fn: (x) => {
      return total_partum_fn(x) === 0
        ? null
        : ((x['sanitary_event_metritis'] / total_partum_fn(x)) * 100).toFixed(2)
    },
  },
  {
    name: 'Anestro > 90DEL (%)',
    data_column_fn: (x) => {
      return x['dairy_cows'] === 0
        ? null
        : ((x['sanitary_event_anestrus'] / x['dairy_cows']) * 100).toFixed(2)
    },
  },
  {
    name: 'Quistes ováricos (%)',
    data_column_fn: (x) => {
      return x['sanitary_event_ovarian_cyst'] === 0
        ? null
        : (
            (x['sanitary_event_anestrus'] / x['sanitary_event_ovarian_cyst']) *
            100
          ).toFixed(2)
    },
  },
  {
    name: 'Tasa de preñez (%)',
    data_column_fn: (x) => {
      return x['sanitary_event_pregnancy_rate']
    },
  },
]

export const problemas_metabolicos = [
  {
    name: 'Hipocalcemia (nº)',
    data_column_fn: (x) => {
      return x['sanitary_event_hypocalcemia']
    },
  },
  {
    name: 'Hipomagnesemia (nº)',
    data_column_fn: (x) => {
      return x['sanitary_event_hypomagnesemia']
    },
  },
  {
    name: 'Laminitis (nº)',
    data_column_fn: (x) => {
      return x['sanitary_event_others_laminitis']
    },
  },
]

export const problemas_metabolicos_porcentaje = [
  {
    name: 'Hipocalcemia (%)',
    data_column_fn: (x) => {
      return total_partum_fn(x) === 0
        ? null
        : (
            (x['sanitary_event_hypocalcemia'] / total_partum_fn(x)) *
            100
          ).toFixed(2)
    },
  },
  {
    name: 'Hipomagnesemia (%)',
    data_column_fn: (x) => {
      return total_partum_fn(x) === 0
        ? null
        : (
            (x['sanitary_event_hypomagnesemia'] / total_partum_fn(x)) *
            100
          ).toFixed(2)
    },
  },
  {
    name: 'Laminitis (%)',
    data_column_fn: (x) => {
      return total_partum_fn(x) === 0
        ? null
        : (
            (x['sanitary_event_others_laminitis'] / total_partum_fn(x)) *
            100
          ).toFixed(2)
    },
  },
]

export const problemas_digestivos = [
  {
    name: 'Meteorismo',
    data_column_fn: (x) => {
      return x['sanitary_event_meteorism']
    },
  },
  {
    name: 'Acidosis',
    data_column_fn: (x) => {
      return x['sanitary_event_acidosis']
    },
  },
  {
    name: 'Parálisis ruminal',
    data_column_fn: (x) => {
      return x['sanitary_ruminal_paralysis']
    },
  },
]

export const problemas_digestivos_porcentaje = [
  {
    name: 'Meteorismo (%)',
    data_column_fn: (x) => {
      return x['dairy_cows'] === 0
        ? null
        : ((x['sanitary_event_meteorism'] / x['dairy_cows']) * 100).toFixed(2)
    },
  },
  {
    name: 'Acidosis (%)',
    data_column_fn: (x) => {
      return x['dairy_cows'] === 0
        ? null
        : ((x['sanitary_event_acidosis'] / x['dairy_cows']) * 100).toFixed(2)
    },
  },
  {
    name: 'Parálisis ruminal (%)',
    data_column_fn: (x) => {
      return x['dairy_cows'] === 0
        ? null
        : ((x['sanitary_ruminal_paralysis'] / x['dairy_cows']) * 100).toFixed(2)
    },
  },
]

export const problemas_podales = [
  {
    name: 'Empedradura',
    data_column_fn: (x) => {
      return x['sanitary_event_cobblestones']
    },
  },
  {
    name: 'Dermatitis',
    data_column_fn: (x) => {
      return x['sanitary_event_dermatitis']
    },
  },
  {
    name: 'Doble suela',
    data_column_fn: (x) => {
      return x['sanitary_event_doble_sole']
    },
  },
  {
    name: 'Otros problemas podales',
    data_column_fn: (x) => {
      return x['sanitary_event_others_legs_problems']
    },
  },
  {
    name: 'Total cojeras',
    data_column_fn: (x) => {
      return problemas_podales_totales_fn(x)
    },
  },
]

export const problemas_podales_porcentaje = [
  {
    name: 'Empedradura (%)',
    data_column_fn: (x) => {
      return problemas_podales_totales_fn(x) === 0
        ? null
        : (
            (x['sanitary_event_cobblestones'] /
              problemas_podales_totales_fn(x)) *
            100
          ).toFixed(2)
    },
  },
  {
    name: 'Dermatitis (%)',
    data_column_fn: (x) => {
      return problemas_podales_totales_fn(x) === 0
        ? null
        : (
            (x['sanitary_event_dermatitis'] / problemas_podales_totales_fn(x)) *
            100
          ).toFixed(2)
    },
  },
  {
    name: 'Doble suela (%)',
    data_column_fn: (x) => {
      return problemas_podales_totales_fn(x) === 0
        ? null
        : (
            (x['sanitary_event_doble_sole'] / problemas_podales_totales_fn(x)) *
            100
          ).toFixed(2)
    },
  },
  {
    name: 'Otros problemas podales (%)',
    data_column_fn: (x) => {
      return problemas_podales_totales_fn(x) === 0
        ? null
        : (
            (x['sanitary_event_others_legs_problems'] /
              problemas_podales_totales_fn(x)) *
            100
          ).toFixed(2)
    },
  },
]

export const problemas_podales_total_porcentaje = [
  {
    name: 'Total cojeras (%)',
    data_column_fn: (x) => {
      return total_cows_fn(x) === 0
        ? null
        : ((problemas_podales_totales_fn(x) / total_cows_fn(x)) * 100).toFixed(
            2,
          )
    },
  },
]

export const partos = [
  {
    name: 'Partos vacas',
    data_column_fn: (x) => {
      return x['pre_partum_cows']
    },
  },
  {
    name: 'Partos vaquillas',
    data_column_fn: (x) => {
      return x['pre_partum_heifers']
    },
  },
  {
    name: 'Total partos',
    data_column_fn: (x) => {
      return total_partum_fn(x)
    },
  },
  {
    name: 'Parto asistido',
    data_column_fn: (x) => {
      return x['sanitary_event_assisted_births']
    },
  },
  {
    name: 'Muertes al parto',
    data_column_fn: (x) => {
      return x['sanitary_event_dead_in_births']
    },
  },
  {
    name: 'Abortos tempranos',
    data_column_fn: (x) => {
      return x['sanitary_event_early_abortions']
    },
  },
  {
    name: 'Abortos tardíos',
    data_column_fn: (x) => {
      return x['sanitary_event_late_abortions']
    },
  },
]

export const partos_porcentaje = [
  {
    name: 'Partos vacas (%)',
    data_column_fn: (x) => {
      return total_partum_fn(x) === 0
        ? null
        : ((x['pre_partum_cows'] / total_partum_fn(x)) * 100).toFixed(2)
    },
  },
  {
    name: 'Partos vaquillas (%)',
    data_column_fn: (x) => {
      return total_partum_fn(x) === 0
        ? null
        : ((x['pre_partum_heifers'] / total_partum_fn(x)) * 100).toFixed(2)
    },
  },
  {
    name: 'Parto asistido (%)',
    data_column_fn: (x) => {
      return total_partum_fn(x) === 0
        ? null
        : (
            (x['sanitary_event_assisted_births'] / total_partum_fn(x)) *
            100
          ).toFixed(2)
    },
  },
  {
    name: 'Muertes al parto (%)',
    data_column_fn: (x) => {
      return total_partum_fn(x) === 0
        ? null
        : (
            (x['sanitary_event_dead_in_births'] / total_partum_fn(x)) *
            100
          ).toFixed(2)
    },
  },
  {
    name: 'Abortos tempranos (%)',
    data_column_fn: (x) => {
      return total_partum_fn(x) === 0
        ? null
        : (
            (x['sanitary_event_early_abortions'] / total_partum_fn(x)) *
            100
          ).toFixed(2)
    },
  },
  {
    name: 'Abortos tardíos (%)',
    data_column_fn: (x) => {
      return total_partum_fn(x) === 0
        ? null
        : (
            (x['sanitary_event_late_abortions'] / total_partum_fn(x)) *
            100
          ).toFixed(2)
    },
  },
]

export const sanidad_mamaria = [
  {
    name: 'Mastitis',
    data_column_fn: (x) => {
      return x['sanitary_event_mastitis']
    },
  },
  {
    name: 'Vacas >200 RCS',
    data_column_fn: (x) => {
      return x['sanitary_event_recount_somatic_cells_200_cows']
    },
  },
  {
    name: 'Vacas > 1M RCS',
    data_column_fn: (x) => {
      return x['sanitary_event_recount_somatic_cells_1M_cows']
    },
  },
]

export const sanidad_mamaria_porcentaje = [
  {
    name: 'Mastitis (%)',
    data_column_fn: (x) => {
      return x['dairy_cows'] === 0
        ? null
        : ((x['sanitary_event_mastitis'] / x['dairy_cows']) * 100).toFixed(2)
    },
  },
  {
    name: 'Vacas >200 RCS (%)',
    data_column_fn: (x) => {
      return x['dairy_cows'] === 0
        ? null
        : (
            (x['sanitary_event_recount_somatic_cells_200_cows'] /
              x['dairy_cows']) *
            100
          ).toFixed(2)
    },
  },
  {
    name: 'Vacas > 1M RCS (%)',
    data_column_fn: (x) => {
      return x['dairy_cows'] === 0
        ? null
        : (
            (x['sanitary_event_recount_somatic_cells_1M_cows'] /
              x['dairy_cows']) *
            100
          ).toFixed(2)
    },
  },
]
